import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { getBuildIdQuery, IActionBar, NcgAppComponentInterface } from '@ncg/data';
import {
    BloomreachService,
    FeatureDetectionService,
    GlobalStateService,
    LazyContentAliases,
    MetaService,
    ScrollService,
    SettingsService,
} from '@ncg/ui';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ncg-root',
    template: `
        <div class="site__wrapper">
            <ngx-json-ld [json]="schema" *ngIf="schema"></ngx-json-ld>
            <div class="site__sticky-top" id="header-portal"></div>
            <div class="content__wrapper">
                <ng-template #vcModelPageNav></ng-template>
                <ncg-header *ngIf="(globalStateService.getState('activeFlow') | async) === 'default'"></ncg-header>
                <router-outlet></router-outlet>
            </div>
            <ng-container *ngIf="(globalStateService.getState('activeFlow') | async) === 'default'" #vcFooter></ng-container>
            <div class="site__sticky-bottom" id="footer-portal"></div>
            <ng-container *ngIf="actionBarData">
                <ng-template ncgLazyComponentLoader [data]="actionBarData" [alias]="lazyContentAliases.ActionBar"></ng-template>
            </ng-container>
            <ncg-page-transition></ncg-page-transition>
            <ng-template ncgLazyComponentLoader [alias]="previewOverlayAlias"></ng-template>
        </div>
        <ncg-up></ncg-up>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit, NcgAppComponentInterface {
    previewOverlayAlias = LazyContentAliases.PreviewOverlay;
    lazyContentAliases = LazyContentAliases;
    actionBarData?: IActionBar;
    schema: any;
    public showFooter = true;
    @ViewChild('vcModelPageNav', { read: ViewContainerRef, static: true })
    viewContainerModelPageNav: ViewContainerRef;
    @ViewChild('vcFooter', { read: ViewContainerRef, static: false })
    viewContainerFooter: ViewContainerRef;
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly translateService: TranslateService,
        private readonly settingsService: SettingsService,
        private readonly featureDetection: FeatureDetectionService,
        private readonly metaService: MetaService,
        private readonly cd: ChangeDetectorRef,
        private readonly scrollService: ScrollService,
        private readonly bloomreachService: BloomreachService,
        public readonly globalStateService: GlobalStateService
    ) {
        this.bloomreachService.initBloomreach();
        this.scrollService.initScrollRestoration();
    }

    ngOnInit() {
        // JSON+LD
        this.settingsService
            .get()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.actionBarData = settings.actionBar;
                const baseUrl = this.metaService.getBaseUrl();

                this.schema = {
                    '@context': 'https://schema.org',
                    '@type': 'Organization',
                    'url': baseUrl,
                    'logo': `${baseUrl}/assets/icons/logo.svg${getBuildIdQuery()}`,
                    'name': settings.companyName,
                };

                this.cd.markForCheck();
            });

        return this.translateService.use('platform');
    }

    ngAfterViewInit(): void {
        if (this.featureDetection.isServer()) {
            return;
        }
        this.globalStateService
            .getStates()
            .pipe(
                map(({ activeFlow }) => activeFlow),
                distinctUntilChanged(),
                takeUntil(this.unsubscribe)
            )
            .subscribe((activeFlow) => {
                if (activeFlow === 'configurator') {
                    return;
                }
                // In order to make sure footer is shown even when starting on pages that do not have it,
                // we must subscribe to changes in the state, and inject accordingly
                window.setTimeout(() => {
                    this._lazyLoadFooter();
                }, 0);
            });
    }

    private async _lazyLoadFooter() {
        if (!this.viewContainerFooter) {
            return;
        }
        this.viewContainerFooter.clear();
        const { FooterComponent } = await import('@features/layout/footer/footer.component');
        this.viewContainerFooter?.createComponent(FooterComponent);
        this.cd.markForCheck();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
