import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SearchDialogService } from '../../search/search-dialog.service';

@Component({
    selector: 'ncg-mobile-header-search',
    template: `<button type="button" (click)="openSearchDialog()" class="button is-clean mobile-header-button">
        <ncg-search-icon></ncg-search-icon>
        <span>
            {{ 'search.button_text' | translate }}
        </span>
    </button>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileHeaderSearchComponent {
    constructor(private readonly searchServiceDialog: SearchDialogService) {}

    openSearchDialog() {
        this.searchServiceDialog.openSearchDialog(true);
    }
}
