import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes, UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer, NoReuseStrategy, PageComponent, PageResolve, PageTransitionResolve } from '@ncg/ui';

const routes: Routes = [
    {
        path: '**',
        component: PageComponent,
        resolve: {
            content: PageResolve,
            transition: PageTransitionResolve,
        },
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'disabled',
            anchorScrolling: 'disabled',
            scrollOffset: [0, 0],
            onSameUrlNavigation: 'reload',
            initialNavigation: 'enabledNonBlocking',
        }),
    ],
    exports: [RouterModule],
    providers: [
        PageResolve,
        PageTransitionResolve,
        {
            provide: RouteReuseStrategy,
            useClass: NoReuseStrategy,
        },
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer,
        },
    ],
})
export class AppRoutingModule {}
